@use '../../styles/vars';

.Error {
    width: 100vw;
    height: 100vh;
    background-image: url('../../assets/images/error.png');
    background-size: cover;
    background-position: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        margin-top: -15rem;
        font-weight: 700;
        font-size: 150px;
        color: #fff;
    }

    p {
        font-weight: 900;
        font-size: 32px;
        color: #fff;
    }

    &-goback--button {
        background-color: vars.$primary-bleu;
        border-radius: 2rem;
        padding: 1rem;
        color: #fff;
        margin-top: 2rem;
    }
}