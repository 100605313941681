.Scene {
    height: 10rem;
    width: 100%;
    margin: auto;
    position: relative;

    &-loading {
        width: 100%;
        height: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #070C3A;
        border-radius: 0.25rem;
        @media screen and (min-width: 900px) {
            max-width: 94%;
            height: 26rem;
            margin: auto;
            border-radius: 0.5rem;
        }
    }

    @media screen and (min-width: 900px) {
        height: 26rem;
    }
    &-no--fonds {
        border-radius: 0.25rem;
        width: 100%;
        height: 100%;
        @media screen and (min-width: 900px) {
            max-width: 94%;
            height: 22rem;
            margin: auto;
            border-radius: 0.5rem;
        }
    }

    &-plan {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 0.25rem;
        z-index: 2;
        @media screen and (min-width: 900px) {
            max-width: 94%;
            border-radius: 0.5rem;
        }
    }
    &-image {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 0.25rem;
        z-index: 1;
        @media screen and (min-width: 900px) {
            max-width: 94%;
            border-radius: 0.5rem;
        }

        &--relative {
            width: 100%;
            height: 100%;
            border-radius: 0.25rem;
            @media screen and (min-width: 900px) {
                border-radius: 0.5rem;
            }
        }
    }
    &-image03 {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        border-radius: 0.25rem;
        @media screen and (min-width: 900px) {
            position: absolute;
            width: 94%;
            z-index: 2;
            height: 100%;
            border-radius: 0.5rem;
        }
        &--relative {
            width: 100%;
            height: 100%;
            border-radius: 0.25rem;
            @media screen and (min-width: 900px) {
                border-radius: 0.5rem;
            }
        }
    }
}