@use '../../../styles/vars';

.FormationResults {
    padding: 1rem;
    background-color: #fff;
    width: 90%;
    margin: 1rem auto 0;
    border-radius: 0.5rem;
    &--blur {
        padding: 1rem;
        background-color: #fff;
        width: 90%;
        margin: 1rem 1.2rem 0;
        border-radius: 0.5rem;
        filter: contrast(0.5);
        box-shadow: 0px 3px 18px rgba(6, 33, 69, 0.2);
        @media screen and (min-width: 900px) {
            filter: none;
            height: 58vh;
            border-radius: 1rem;
        }
    }

    &-buttons {
        display: flex;
        margin-top: 2rem;
        @media screen and (max-width: 900px) {
            flex-direction: column;
            width: 100%;
        }
    }

    &-retry {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        color: vars.$primary-bleu;
        font-weight: 700;
        font-size: 0.9rem;
        padding: 0.8rem;
        border: none;
        border-radius: 2rem;
        margin-top: 2rem;
        @media screen and (max-width: 900px) {
            width: 90%;
            border-radius: 1.25rem;
            margin: 1rem auto;
            padding: 0.8rem;
            
        }

        &--icon {
            margin-right: 1rem;
        }
    }

    &-title {
        margin-bottom: 1.2rem;
    }

    &-subtitle {
        margin-bottom: 0.8rem;
        max-width: 13.5rem;
        text-align: center;
    }

    &-block {
        width: 100%;
        @media screen and (min-width: 900px) {
            height: 100%;
            width: 50%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            padding-bottom: 3rem;
            position: relative;
        }
        &--question {
            margin-bottom: 1.5rem;
            width: 100%;
            &:hover {
                cursor: pointer;
            }
            @media screen and (min-width: 900px) {
                padding-left: 4rem;
                padding-right: 4.5rem;
            }
        }
    }

    @media screen and (min-width: 900px) {
        &-bar {
            padding-bottom: 1rem;
            position: absolute;
            top: 20rem;
            left: 95%;
        
            &::before {
                content: "";
                padding-top: 22rem;
                border: 1px solid vars.$tertiary-bleu;
            }
        }
    }
}