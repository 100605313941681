@use '../../../../styles/vars';

.UserLogs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 4rem;
    width: 100%;

    &::before {
        content: "";
        border-top: 1px solid vars.$primary-bleu;;
        position: relative;
        top: 0;
        width: 70%; 
    }

    &-block {
        display: flex;
        align-items: center;
        width: 60%;
        text-align: left;
        margin-top: 2rem;
        cursor: pointer;

        &--img {
            margin-right: 0.5rem;
            width: 40px;
        }

        &--user {
            color: vars.$primary-bleu;
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-weight: 500;
        }
    }

    &-btn {
        background-color: #fff;
        padding: 1rem 1.1rem;
        border: 1px solid vars.$primary-bleu;
        border-radius: 5rem;
        margin-top: 1.8rem;
        color: vars.$primary-bleu;
        font-weight: 700;
        width: 70%;

        &:hover {
            background-color: vars.$secondary-bleu-medium;
            color: #fff;
        }
    }

    &-language {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 30%;
        margin-top: 1.8rem;
        color: vars.$primary-bleu;
        font-weight: 700;

        &--select {
            // cursor: pointer;
            border: none;
            background-color: #fff;
            color: vars.$primary-bleu;
            font-weight: 700;

            &:hover {
                cursor: pointer;
            }
        }
    }
}