@use '../../../styles/vars';

.LoginForm {
    width: 335px;

    &-image {
        width: 22rem;
        margin-bottom: -2rem;
        @media screen and (min-width: 900px) {
            width: 26rem;
            margin-bottom: -2rem;
            position: relative;
            left: -3rem;
            
        }
    }
    &-welcome {
        margin-bottom: 1rem;
        color: vars.$primary-bleu-fonce;
        font-size: 30px;
        font-weight: 700;
    }

    &-form {
        &--label {
            color: vars.$primary-gris;
            font-weight: 700;
            font-size: 14px;
            display: block;
        }
        &--input {
            border: 1px solid vars.$primary-bleu;
            border-radius: 7px;
            width: 100%;
            height: 40px;
            margin-bottom: 0.5rem;
            padding-left: 0.5rem;
        }
        
        &--buttons {
            display: flex;
            justify-content: space-between;
            margin-top: -1rem;
        }

        &--forgot-password {
            color: vars.$primary-framboise;
            border: none;
            background: none;
            font-weight: 700;
            font-size: 1rem;
            padding: 0;

            &:hover {
                text-decoration: underline;
            }
        }
        &--submit {
            color: #fff;
            background-color: vars.$primary-bleu;
            border-radius: 2.2rem;
            font-size: 1rem;
            padding: 1rem;
            border: none;

            &:hover {
                background-color: vars.$secondary-bleu-medium;
            }
        }
    }
}