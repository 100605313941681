/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-hygiene01_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Hygiene/icon-hygiene01_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    background-position-x: -3.5rem;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      background-position-x: -7.5rem;
      border-radius: 0.5rem;
      max-width: 94%;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-hygiene02_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Hygiene/icon-hygiene02_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-hygiene03_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Hygiene/icon-hygiene03_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-hygiene04_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Hygiene/icon-hygiene04_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-hygiene05_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Hygiene/icon-hygiene05_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-hygiene06_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Hygiene/icon-hygiene06_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-hygiene07_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Hygiene/icon-hygiene07_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-hygiene08_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Hygiene/icon-hygiene08_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-hygiene09_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Hygiene/icon-hygiene09_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-hygiene10_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Hygiene/icon-hygiene10_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-hygiene11_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Hygiene/icon-hygiene11_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-hygiene12_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Hygiene/icon-hygiene12_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-hygiene13_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Hygiene/icon-hygiene13_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }