@use 'reset';
@use 'vars';
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');



body {
  line-height: 1.6;
  font-family: 'Lato', sans-serif;
  // @media screen and (min-width: 1280px) {
  //   overflow: hidden;
    
  // }
}

main {
  background-color: vars.$tertiary-bleu;
  padding-bottom: 2rem;
  min-height: 100vh;
}

h1,
h2,
h3 {
  font-weight: 700;
  line-height: 1.2;
}