@use '../../../styles/vars';

.NavBar {
    &-open {
        background-color: #fff;
        min-width: 239px;
        min-height: 100%;
        position: fixed;
        right: 0;
        top: 0;
        border-radius: 2rem 0 0 2rem;
        z-index: 1;
        padding-right: 2rem;
        @media screen and (min-width: 900px) {
            position: fixed;
            left: 0;
            width: 14rem;
            border-radius: 0;


            &::before {
                content: "";
                background-image: url('../../../assets/images/logo-supplay-nav.png');
                position: absolute;
                top: 5rem;
                left: 5rem;
                width: 7rem;
                height: 4rem;
                background-repeat: no-repeat;
            }
        }
    }
    &-button {
        background-color: vars.$primary-bleu-fonce;
        width: 42px;
        height: 42px;
        border-radius: 0.5rem;
        border: none;
        position: absolute;
        right: 1rem;
        top: 1rem;
        color: #fff;
        font-size: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (min-width: 900px) {
            display: none;
        }
    }

}

.NavMenu  {
    margin-top: 10rem;
    margin-bottom: 3rem;
    margin-left: 4rem;

    @media screen and (min-width: 900px) {
        margin-left: 2rem;
        margin-top: 13rem;
    }
    &-element {
        margin-bottom: 2rem;
        color: vars.$primary-bleu;
        font-weight: 500;
    }

    &-element:hover {
        cursor: pointer;
        scale: 1.1;
        transition: 0.5s;
    }

    &-element:hover::before {
        content: "";
        position: relative;
        left: 2.3rem;
        top: 2.5rem;
        width: 15%;
        display: block;
        border-bottom: 1px solid vars.$primary-bleu;
    }
}

