/** Colors **/
// Framboise
$primary-framboise: #E70F47;
$secondary-framboise: #F59FB5;

// Gris
$primary-gris: #36424A;

// Jaune
$primary-jaune: #F2AE2E;

// Vert
$primary-vert: #26A653;

// Bleus
$primary-bleu: #323BA2;
$primary-bleu-fonce: #070C3A;
$secondary-bleu: #ADB1DA;
$secondary-bleu-medium: #2D348F;
$tertiary-bleu: #F4F5FF;