@use '../../../styles/vars';

.Achievements {
    font-size: 13px;
    min-width: 49%;
    padding: 0.5rem 0 1rem 0;
    border-radius: 0.25rem;
    background: #fff;
    @media screen and (min-width: 900px) {
        padding: 1rem 0.5rem;
        border-radius: 0.5rem;
        margin-right: 0.5rem;
    }
    &:last-child {
        margin-top: 0.5rem;
        width: 100%;
        @media screen and (min-width: 900px) {
            margin-top: 0;
        }
    }
    @media screen and (min-width: 900px) {
        min-width: 32.5%;
        font-size: 1rem;
    }

    &-icon {
        margin-right: 0.5rem;
        width: 1.5rem;
        @media screen and (min-width: 900px) {
            width: 2rem;
        }
    }

    &-status {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        @media screen and (min-width: 900px) {
            justify-content: start;
        }
        &:last-child {
            margin-top: 0.5rem;
            justify-content: center;
            @media screen and (min-width: 900px) {
                justify-content: start;
                margin-left: 2rem;
            }
        }

        &:hover {
            cursor: pointer;
        }

        &--number {
            color: vars.$primary-bleu;
            margin-right: 0.5rem;
        }

        &--chevron {
            margin-left: 1rem;
            color: vars.$primary-bleu;
        }
    }

    &-modal {
        &--button {
            position: absolute;
            right: 2rem;
            cursor: pointer;
        }
        &--block {
            margin: 1rem 0 1rem 2rem;

        }
        &--list {
            color: vars.$primary-bleu;
            list-style: disc;
            margin-bottom: 0.5rem;
            cursor: pointer;
        }
    }

    &-formations {
        color: vars.$primary-bleu;
        margin: 1rem 1rem 1rem 1.5rem;
        @media screen and (min-width: 900px) {
            margin: 1rem 2rem;   
        }
        &--list {
            list-style: disc;

            &:hover {
                cursor: pointer;
                font-weight: 700;
            }
        }
    }
}

.Achievements-modal--questions {
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 0;
    z-index: 0;
    position: relative;
    width: 100%;
    margin: auto;
    border-radius: 0.5rem;
    @media screen and (min-width: 900px) {
        border-radius: 1rem;
    }
    &-title {
        margin-bottom: 1rem;
        font-size: 1.2rem;
        font-weight: 700;
        padding-left: 1rem;
    }
    
    &-block {
    // background-image: url('../../../assets/images/plain_curve.svg');
    // background-repeat: no-repeat;
    // background-position-y: center;
    // background-size: 80%;
    // background-position-x: center;
    max-height: 15rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    & :nth-child(1) {
        order: 1;
    }
    & :nth-child(2) {
        order: 2;
    }
    & :nth-child(3) {
        order: 3;
    }
    & :nth-child(4) {
        order: 6;
    }
    & :nth-child(5) {
        order: 5;
    }
    & :nth-child(6) {
        order: 4;
    }
    & :nth-child(7) {
        order: 7;
    }
    & :nth-child(8) {
        order: 8;
    }
    & :nth-child(9) {
        order: 9;
    }
    & :nth-child(10) {
        order: 12;
    }
    & :nth-child(11) {
        order: 11;
    }
    & :nth-child(12) {
        order: 10;
    }
    & :nth-child(13) {
        order: 13;
    }
    & :nth-child(14) {
        order: 14;
    }
    }

    &-button {
        background-color: vars.$primary-bleu;
        color: #fff;
        font-weight: 700;
        font-size: 1.2rem;
        cursor: pointer;
        width: 90%;
        margin: 1rem 1rem;
        padding: 1rem;
        border-radius: 1.25rem;
        border: none;
        @media screen and (min-width: 900px) {
         border-radius: 2rem;   
        }

    }

    &-list {
        width: 3rem;
        height: 3rem;
        padding: 0.5rem;
        border-radius: 3rem;
        margin-bottom: 2rem;
        text-align: center;
        cursor: pointer;
        color: #fff;
        font-weight: 700;
        font-size: 1.2rem;


    }
}


.progress-container {
    width:100%;
    margin: 2em auto; 
  }
.progress-steps {
    counter-reset: step;
}
.progress-items {
    list-style-type: none;
    width: 25%;
    float: left;
    font-size: 1rem;
    font-weight: 400;
    position: relative;
    text-align: center;
    color: #fff;
    &::before {
        width: 3.5rem;
        height: 3.5rem;
        content: counter(step);
        counter-increment: step;
        line-height: 3rem;
        border: 2px solid vars.$secondary-framboise;
        font-weight: 700;
        font-size: 1.2rem;
        display: block;
        text-align: center;
        margin: 0 auto .7em auto;
        border-radius: 50%;
        background-color: vars.$primary-bleu-fonce;
    }
    &::after {
        width: 100%;
        height: 1px;
        content: '';
        position: absolute;
        background-color: #fff;
        top: 1.5rem;
        left: 0;
        z-index: -1;
    }
}

.progress-items--complete {
    list-style-type: none;
    width: 25%;
    float: left;
    font-size: 1rem;
    font-weight: 400;
    position: relative;
    text-align: center;
    color: #fff;
    &::before {
        width: 3.5rem;
        height: 3.5rem;
        content: counter(step);
        counter-increment: step;
        line-height: 3rem;
        border: 2px solid vars.$primary-vert;
        font-weight: 700;
        font-size: 1.2rem;
        display: block;
        text-align: center;
        margin: 0 auto .7em auto;
        border-radius: 50%;
        background-color: vars.$primary-vert;
    }
    &::after {
        width: 100%;
        height: 1px;
        content: '';
        position: absolute;
        background-color: #fff;
        top: 1.5rem;
        left: 0;
        z-index: -1;
    }
}

.progress-items--active {
    list-style-type: none;
    width: 25%;
    float: left;
    font-size: 1rem;
    font-weight: 400;
    position: relative;
    text-align: center;
    color: #fff;
    &::before {
        width: 3.5rem;
        height: 3.5rem;
        content: counter(step);
        counter-increment: step;
        line-height: 3rem;
        border: 2px solid vars.$primary-framboise;
        font-weight: 700;
        font-size: 1.2rem;
        display: block;
        text-align: center;
        margin: 0 auto .7em auto;
        border-radius: 50%;
        background-color: vars.$primary-framboise;
    }
    &::after {
        width: 100%;
        height: 1px;
        content: '';
        position: absolute;
        background-color: #fff;
        top: 1.5rem;
        left: 0;
        z-index: -1;
    }
}

.progress-items:hover {
    &::before {
        cursor: pointer;
        background-color: vars.$secondary-framboise;
    }
}