.Login {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    // padding-bottom: 2rem;
    height: 120%;
    @media screen and (min-width: 900px) {
        flex-direction: row;
        margin-bottom: 0;
    }
    &-side--left {
        background: linear-gradient(180deg, #EC4333 0%, #E70F47 100%);
        width: 100vw;
        height: 30vh;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (min-width: 900px) {
            width: 50vw;
            height: 100vh;
        }
    }
    &-side--right {
        background-color: #fff;
        display: flex;
        justify-content: center;
        @media screen and (min-width: 900px) {
            width: 50vw;
            height: 100vh;
            padding-top: 5%;
        }
    }
}