.Header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 72px;
    background-color: #fff;

    &-blur {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 72px;
        background-color: #c0c1cc;
        @media screen and (min-width: 900px) {
            background-color: #fff;
            margin-left: 18rem;
        }
    }
    &-title {
        font-weight: 700;
        font-size: 1.7rem;
        margin-right: 3rem;
        @media screen and (min-width: 900px) {
            font-size: 2rem;
        }
    }
}