@use '../../../../styles/vars';


.Question {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: vars.$primary-bleu-fonce;
    color: #fff;
    width: 100%;
    @media screen and (min-width: 900px) {
        border-radius: 1rem;
        padding: 2rem;
    }

    &-welcome {
        font-weight: 700;
        font-size: 14px;
        &--blur {
            font-size: 14px;
            filter: contrast(50%);
            @media screen and (min-width: 900px) {
                filter: none;
            }
        }
    }
    @media screen and (min-width: 900px) {
        height: 100%;
        width: 100%;
        margin-top: 0;
    }

    &--title {
        font-size: 12px;
        @media screen and (min-width: 900px) {
            font-size: 1.2rem;
        }
    }

    &--number {
        font-size: 16px;
        @media screen and (min-width: 900px) {
            margin-bottom: 1rem;
            font-size: 24px;
        }
        &-length {
            font-size: 12px;
            @media screen and (min-width: 900px) {
                font-size: 16px;
            }
        }
    }

    &--text {
        font-size: 1rem;
        @media screen and (min-width: 900px) {
            font-size: 1.7rem;
        }

        &::after {
            content: "";
            border-bottom: 1px solid #fff;
            margin-top: 0.5rem;
            width: 10rem;
            display: block;
            @media screen and (min-width: 900px) {
                margin-top: 1.5rem;
            }
        }
    }

    &-status {
        margin: 0.5rem 0;
        display: flex;
        align-items: center;
        font-size: 1rem;
        @media screen and (min-width: 900px) {
            font-size: 1.3rem;
            margin-top: 1.5rem;
        }

        &--image {
            width: 38px;
            @media screen and (min-width: 900px) {
                width: 63px;
            }
        }

        & span {
            margin-left: 0.8rem;
        }

        &--text {
            font-size: 0.85rem;
            font-weight: 500;
            @media screen and (min-width: 900px) {
                font-size: 1rem;
                margin-top: 1.5rem;
            }
        }
    }

}