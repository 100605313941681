@use '../../../styles/vars';

.Questionary {
    padding: 0 1rem;

    &_modal {
        display: flex;
        flex-direction: column;
        margin: auto;
        align-items: center;
        text-align: center;

        &__text{
            font-size: 1rem;
            font-weight: 700;
            margin-bottom: 1rem;
            width: 50%;
        }

        &__button {
            padding: 1rem 2rem;
            border-radius: 2rem;
            background-color: vars.$primary-bleu;
            color: white;
            font-size: 0.9rem;
            font-weight: 600;
            border: none;
            cursor: pointer;
            width: 60%;
            max-width: 100%;
            margin: 1rem auto 0;
            
            @media screen and (max-width: 800px) {
                width: 80%;
                border-radius: 0.5rem;
            }
        }
    }

    &-questions {
        background-color: #fff;
        border-radius: 0.5rem;
        margin-top: 1rem;
        padding: 0 1.5rem 1.5rem;
        box-shadow: 0px 3px 18px rgba(6, 33, 69, 0.2);
        @media screen and (min-width: 900px) {
            border-radius: 1rem;
            padding: 1.5rem;
        }
        &--blur {
            background-color: #fff;
            border-radius: 0.5rem;
            margin-top: 1rem;
            padding: 0 1.5rem 1.5rem;
            box-shadow: 0px 3px 18px rgba(6, 33, 69, 0.2);
            filter: contrast(0.5);
            @media screen and (min-width: 900px) {
                filter: none;
                border-radius: 1rem;
                padding: 0.5rem 1.5rem 2rem;
                margin-top: 3rem;
            }
         }

        &--title {
            font-weight: 700;
            font-size: 1.2rem;

            & div {
                background-color: red;
                width: 3rem;
                height: 0.3rem;
                margin-top: 0.3rem;
            }
        }
    }
}