@use '../../../../styles/vars';

// CUSTOM CHECKBOX
input[type="radio"], input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid vars.$primary-bleu;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 0.5rem;

    &:checked {
      background-color: vars.$primary-bleu;
      color: #fff;
      width: 1rem;
    }

    &:checked:after {
      content: '';
      display: block;
      position: relative;
      left: 1px;
      top: 5px;
      background: #fff;
      width: 2px;
      height: 2px;
      box-shadow: 
        2px 0 0 #fff,
        4px 0 0 #fff,
        4px -2px 0 #fff,
        4px -4px 0 #fff,
        4px -6px 0 #fff,
        4px -8px 0 #fff;
      transform: rotate(45deg);
    }
  }

  .SupplayForm {
    &-subquestions, &-questions {
      font-size: 0.9rem;
      &-hidden {
        display: none;
      }
      &--question {
        margin: 1rem 0 0 2rem;
        list-style: disc;
        color: #000;
        font-weight: 500;
      }
      &--answer {
        margin: 0.5rem;
        display: flex;
        color: vars.$primary-bleu;
        font-weight: 500;
        font-size: 0.9rem;
        flex-wrap: wrap;
        &-choice {
          display: flex;
          align-items: center;
          margin: 0 1rem;
        }
        &-answer {
          display: flex;
          align-items: center;
          min-width: 5.3rem;
          margin: 0.3rem 0.9rem 0 0.5rem;
          position: relative;
        }
      }
      &--subanswer {
        @media screen and (min-width: 900px) {
          display: flex;
        }
        &-choice {
          display: flex;
          align-items: center;
          margin: 0.5rem 0;
          color: vars.$primary-bleu;
          @media screen and (min-width: 900px) {
            margin-left: 1.5rem;  
          }
        }
      }
    }

    &-block {
      margin-top: 1rem;
      font-size: 1rem;

      &--question {
        font-weight: 700;
        margin-bottom: 1rem;
      }
      

      &--submit {
        padding: 1rem 2rem;
        border-radius: 1em;
        background-color: vars.$primary-bleu;
        color: white;
        font-size: 0.9rem;
        font-weight: 600;
        border: none;
        cursor: pointer;
        width: 100%;
        margin: 1rem auto 0;
        @media screen and (min-width: 900px) {
          border-radius: 2rem;
        }
        &:disabled {
          padding: 1rem 2rem;
          border-radius: 1rem;
          background-color: vars.$secondary-bleu;
          color: white;
          font-size: 0.9rem;
          font-weight: 600;
          border: none;
          pointer-events: none;
          width: 100%;
          margin: 1rem auto 0;
          @media screen and (min-width: 900px) {
            border-radius: 2rem;
          }
        } 
      }

      &--goback {
        padding: 1rem 2rem;
        border-radius: 1rem;
        border: 1px solid vars.$primary-bleu;
        color: vars.$primary-bleu;
        background-color: #fff;
        font-size: 0.9rem;
        font-weight: 600;
        cursor: pointer;
        width: 100%;
        margin: 1rem auto 0;
        @media screen and (min-width: 900px) {
          border-radius: 2rem;
        }
          &:hover {
            background-color: vars.$primary-bleu;
            color: #fff;
          }   
      }
  }
}
  
  .checkIcon {
    position: relative;
    left: -1.5rem;
    width: 1rem;
    color: white;
  }

  @media screen and (min-width: 900px) {
    .SupplayForm-questions--answer, .SupplayForm-questions {
      margin-left: 2.5rem;  
    }
  }