@use '../../../styles/vars';

.Formation-sections {
    margin-top: 0;
    padding: 0 1rem;
    height: 100%;
    
    @media screen and (min-width: 900px) {
        height: 86vh;
        display: flex;
        padding: 0.5rem;
    }
}

.FormationPage {
    @media screen and (min-width: 900px) {
        height: 100vh;
    }
    
    &-Question {
        @media screen and (min-width: 900px) {
            width: 32rem;
            margin-right: 0.5rem;
        }
    }
    
    &-Answer {
        padding: 1rem;
        border-radius: 0.5rem;
        background-color: #fff;
        margin-top: 1rem;
        @media screen and (min-width: 900px) {
            padding: 2rem;
            margin-top: 2rem;
            border-radius: 1.5rem;
            height: 90vh;
        }
    
        &--blur {
            padding: 1rem;
            border-radius: 0.5rem;
            background-color: #fff;
            margin-top: 2rem;
            filter: contrast(50%);
            color: vars.$primary-bleu;
            @media screen and (min-width: 900px) {
                filter: none;
                width: 62%;
                height: 100%;
                margin: auto;
                position: relative;
                padding: 3rem 1.25rem;
                border-radius: 1rem;
            }
        }
    
    }
}