/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-transport01_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Transport/icon-transport01_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-transport02_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Transport/icon-transport02_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-transport03_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Transport/icon-transport03_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-transport04_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Transport/icon-transport04_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-transport05_Illus00Plan03 {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Transport/icon-transport05_Illus00Plan03.png');
    border-radius: 0.25rem;
    background-position-x: -4rem;
    background-size: cover;
    height: 100%;
    @media screen and (min-width: 900px) {
      background-position-x: -6rem;
      border-radius: 0.5rem;
      max-width: 94%;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-transport06_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Transport/icon-transport06_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-transport07_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Transport/icon-transport07_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-transport08_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Transport/icon-transport08_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-transport09_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Transport/icon-transport09_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-transport10_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Transport/icon-transport10_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  