/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-Logistique01_Illus00Fond {
  background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Common/icon-Logistique01_Illus00Fond.png');
  border-radius: 0.25rem;
  background-position-x: -11rem;
  height: 100%;
  background-size: cover;
  @media screen and (min-width: 900px) {
    background-position: contain;
    border-radius: 0.5rem;
    max-width: 94%;
    height: 100%;
    margin: auto;
  }
}

.icon-Logistique02_Illus00Fond {
  background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Common/icon-Logistique02_Illus00Fond.png');
  border-radius: 0.25rem;
  background-position-x: -11rem;
  height: 100%;
  background-size: cover;
  @media screen and (min-width: 900px) {
    background-position: contain;
    border-radius: 0.5rem;
    max-width: 94%;
    height: 100%;
    margin: auto;
  }
}

.icon-Logistique03_Illus00Fond {
  background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Common/icon-Logistique03_Illus00Fond.png');
  border-radius: 0.25rem;
  background-position-x: -11rem;
  height: 100%;
  background-size: cover;
  @media screen and (min-width: 900px) {
    background-position: contain;
    border-radius: 0.5rem;
    max-width: 94%;
    height: 100%;
    margin: auto;
  }
}

.icon-Logistique04_Illus00Fond {
  background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Common/icon-Logistique04_Illus00Fond.png');
  border-radius: 0.25rem;
  background-position-x: -11rem;
  height: 100%;
  background-size: cover;
  @media screen and (min-width: 900px) {
    background-position: contain;
    border-radius: 0.5rem;
    max-width: 94%;
    height: 100%;
    margin: auto;
  }
}

.icon-Logistique05_Illus00Fond {
  background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Common/icon-Logistique05_Illus00Fond.png');
  border-radius: 0.25rem;
  background-position-x: -11rem;
  height: 100%;
  background-size: cover;
  @media screen and (min-width: 900px) {
    background-position: contain;
    border-radius: 0.5rem;
    max-width: 94%;
    height: 100%;
    margin: auto;
  }
}

.icon-Logistique06_Illus00Fond {
  background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Common/icon-Logistique06_Illus00Fond.png');
  border-radius: 0.25rem;
  background-position-x: -11rem;
  height: 100%;
  background-size: cover;
  @media screen and (min-width: 900px) {
    background-position: contain;
    border-radius: 0.5rem;
    max-width: 94%;
    height: 100%;
    margin: auto;
  }
}

.icon-Logistique07_Illus00Fond {
  background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Common/icon-Logistique07_Illus00Fond.png');
  border-radius: 0.25rem;
  background-position-x: -11rem;
  height: 100%;
  background-size: cover;
  @media screen and (min-width: 900px) {
    background-position: contain;
    border-radius: 0.5rem;
    max-width: 94%;
    height: 100%;
    margin: auto;
  }
}

.icon-Logistique08_Illus00Fond {
  background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Common/icon-Logistique08_Illus00Fond.png');
  border-radius: 0.25rem;
  background-position-x: -11rem;
  height: 100%;
  background-size: cover;
  @media screen and (min-width: 900px) {
    background-position: contain;
    border-radius: 0.5rem;
    max-width: 94%;
    height: 100%;
    margin: auto;
  }
}

.icon-Logistique09_Illus00Fond {
  background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Common/icon-Logistique09_Illus00Fond.png');
  border-radius: 0.25rem;
  background-position-x: -11rem;
  height: 100%;
  background-size: cover;
  @media screen and (min-width: 900px) {
    background-position: contain;
    border-radius: 0.5rem;
    max-width: 94%;
    height: 100%;
    margin: auto;
  }
}

.icon-Logistique10_Illus00Fond {
  background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Common/icon-Logistique10_Illus00Fond.png');
  border-radius: 0.25rem;
  background-position-x: -11rem;
  height: 100%;
  background-size: cover;
  @media screen and (min-width: 900px) {
    background-position: contain;
    border-radius: 0.5rem;
    max-width: 94%;
    height: 100%;
    margin: auto;
  }
}

.icon-Logistique11_Illus00Fond {
  background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Common/icon-Logistique11_Illus00Fond.png');
  border-radius: 0.25rem;
  background-position-x: -11rem;
  height: 100%;
  background-size: cover;
  @media screen and (min-width: 900px) {
    background-position: contain;
    border-radius: 0.5rem;
    max-width: 94%;
    height: 100%;
    margin: auto;
  }
}

.icon-Logistique12_Illus00Fond {
  background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Common/icon-Logistique12_Illus00Fond.png');
  border-radius: 0.25rem;
  background-position-x: -11rem;
  height: 100%;
  background-size: cover;
  @media screen and (min-width: 900px) {
    background-position: contain;
    border-radius: 0.5rem;
    max-width: 94%;
    height: 100%;
    margin: auto;
  }
}

.icon-Logistique13_Illus00Fond {
  background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Common/icon-Logistique13_Illus00Fond.png');
  border-radius: 0.25rem;
  background-position-x: -11rem;
  height: 100%;
  background-size: cover;
  @media screen and (min-width: 900px) {
    background-position: contain;
    border-radius: 0.5rem;
    max-width: 94%;
    height: 100%;
    margin: auto;
  }
}
