/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-Batiment01_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Batiment/icon-Batiment01_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Batiment02_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Batiment/icon-Batiment02_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Batiment03_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Batiment/icon-Batiment03_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Batiment04_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Batiment/icon-Batiment04_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Batiment05_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Batiment/icon-Batiment05_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Batiment06_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Batiment/icon-Batiment06_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Batiment07_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Batiment/icon-Batiment07_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Batiment08_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Batiment/icon-Batiment08_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Batiment09_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Batiment/icon-Batiment09_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Batiment10_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Batiment/icon-Batiment10_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Batiment11_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Batiment/icon-Batiment11_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Batiment12_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Batiment/icon-Batiment12_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Batiment13_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Batiment/icon-Batiment13_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }

  .icon-Batiment14_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Batiment/icon-Batiment14_Illus00Fond.png');
    border-radius: 0.25rem;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }