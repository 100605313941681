@use '../../../../styles/vars';

.Answer {
    color: vars.$primary-bleu;
        @media screen and (min-width: 900px) {
            margin: auto;
        }


    &-choices {
        font-size: 12px;
        margin: 1rem auto;
        @media screen and (min-width: 900px) {
            font-size: 16px;
            width: 95%;
        }
        &--list {
            padding: 0.6rem;
            border-radius: 1rem;
            margin-bottom: 0.2rem;
            &:hover {
                cursor: pointer;
            }
            &:hover:not(.Answer-choices--list-seclected) {
                background-color: vars.$secondary-bleu;
                color: #fff;
            }

            &-seclected {
                background-color: vars.$primary-bleu;
                color: #fff;
            }

            &-handleAnswer {
                color: #fff;
                padding: 1rem;
                border-radius: 1rem;
                margin-bottom: 0.5rem;
            }
            &-choice {
                font-weight: 700;
            }

        }
        &--validate {
            padding: 1rem;
            border: none;
            border-radius: 1.25rem;
            color: #fff;
            background: vars.$primary-bleu;
            font-weight: 700;
            width: 100%;

            &-disabled {
                padding: 1rem;
                border: none;
                border-radius: 1.25rem;
                color: #fff;
                font-weight: 700;
                width: 100%;
                cursor: auto;
                background-color: vars.$secondary-bleu;
                @media screen and (min-width: 900px) {
                    border-radius: 2rem;
                }
            }
            @media screen and (min-width: 900px) {
                width: 14rem;
                font-size: 1rem;
                border-radius: 2rem;
                position: absolute;
                bottom: 2rem;
                right: 2rem;

                &-disabled {
                    width: 14rem;
                    font-size: 1rem;
                    position: absolute;
                    right: 2rem;
                    bottom: 2rem;
                    color: #fff;
                    font-weight: 700;
                    cursor: auto;
                    background-color: vars.$secondary-bleu;
                }
            }
        }
    }
}