/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-Industrie01_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Industrie/icon-Industrie01_Illus00Fond.png');
    border-radius: 0.25rem;
    width: 100%;
    height: 100%;
    background-size: cover;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Industrie02_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Industrie/icon-Industrie02_Illus00Fond.png');
    border-radius: 0.25rem;
    width: 100%;
    height: 100%;
    background-size: cover;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Industrie03_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Industrie/icon-Industrie03_Illus00Fond.png');
    border-radius: 0.25rem;
    width: 100%;
    height: 100%;
    background-size: cover;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Industrie04_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Industrie/icon-Industrie04_Illus00Fond.png');
    border-radius: 0.25rem;
    width: 100%;
    height: 100%;
    background-size: cover;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Industrie05_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Industrie/icon-Industrie05_Illus00Fond.png');
    border-radius: 0.25rem;
    width: 100%;
    height: 100%;
    background-size: cover;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Industrie06_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Industrie/icon-Industrie06_Illus00Fond.png');
    border-radius: 0.25rem;
    height: 100%;
    background-size: cover;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Industrie07_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Industrie/icon-Industrie07_Illus00Fond.png');
    border-radius: 0.25rem;
    width: 100%;
    height: 100%;
    background-size: cover;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Industrie08_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Industrie/icon-Industrie08_Illus00Fond.png');
    border-radius: 0.25rem;
    width: 100%;
    height: 100%;
    background-size: cover;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Industrie09_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Industrie/icon-Industrie09_Illus00Fond.png');
    border-radius: 0.25rem;
    width: 100%;
    height: 100%;
    background-size: cover;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Industrie10_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Industrie/icon-Industrie10_Illus00Fond.png');
    border-radius: 0.25rem;
    width: 100%;
    height: 100%;
    background-size: cover;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Industrie11_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Industrie/icon-Industrie11_Illus00Fond.png');
    border-radius: 0.25rem;
    width: 100%;
    height: 100%;
    background-size: cover;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Industrie12_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Industrie/icon-Industrie12_Illus00Fond.png');
    border-radius: 0.25rem;
    width: 100%;
    height: 100%;
    background-size: cover;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }
  
  .icon-Industrie13_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Industrie/icon-Industrie13_Illus00Fond.png');
    border-radius: 0.25rem;
    width: 100%;
    height: 100%;
    background-size: cover;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;
    }
  }

  .icon-Industrie14_Illus00Fond {
    background-image: url('https://f3s-supplay.external.ouidou.fr/api/images/Industrie/icon-Industrie14_Illus00Fond.png');
    border-radius: 0.25rem;
    width: 100%;
    height: 100%;
    background-size: cover;
    @media screen and (min-width: 900px) {
      max-width: 94%;
      border-radius: 0.5rem;
      height: 100%;
      margin: auto;    }
  }