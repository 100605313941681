@use '../../styles/vars';

/* Hide scrollbar for Chrome, Safari and Opera */
.Monitoring-achievements::-webkit-scrollbar {
    display: none;
}

.Monitoring {
    background-color: #fff;
    width: 90%;
    margin: auto;
    border-radius: 0.5rem;
    padding: 1rem;
    &--blur {
        background-color: #fff;
        width: 90%;
        margin: auto;
        border-radius: 0.5rem;
        padding: 1rem;
        filter: contrast(50%);
        @media screen and (min-width: 900px) {
            filter: none;
            border-radius: 1rem;
            padding: 1.5rem 1.5rem;
            margin-left: 1.2rem;
        }
    }
    @media screen and (min-width: 900px) {
        font-size: 1.3rem;
    }
    
    &-title {
        margin-left: 0.5rem;
        margin-bottom: 0.5rem;
        @media screen and (min-width: 900px) {
            margin-bottom: 1.25rem;
        }
    }
    &-achievements {
        // overflow-x: auto;
        // -ms-overflow-style: none;  /* IE and Edge */
        // scrollbar-width: none;  /* Firefox */
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        filter: drop-shadow(0px 3px 18px rgba(6, 33, 69, 0.2));
        @media screen and (min-width: 900px) {
            justify-content: space-between;
            flex-wrap: nowrap;
        }
    }
}
