@use '../../styles/vars';

.HomePage {
    max-height: 100vh;
    @media screen and (min-width: 900px) {
        overflow: hidden;
    }
    &-main {
        padding-top: 1rem;
        &--blur{
            background-color: #bbbccc;
            padding-top: 1rem;
            @media screen and (min-width: 900px) {
                padding: 2.5rem 1.5rem 0 17.5rem;
                background-color: vars.$tertiary-bleu;
            }
        }
    }

    &-welcome {
        width: 90%;
        margin: auto;
        background-color: vars.$primary-bleu-fonce;
        color: #ffff;
        border-radius: 0.5rem;
        padding: 1rem;
        min-height: 9rem;
        font-weight: 700;
        font-size: 0.9rem;
        &--blur {
            font-size: 0.9rem;
            min-height: 9rem;
            width: 90%;
            margin-left: 1.2rem;
            background-color: vars.$primary-bleu-fonce;
            filter: contrast(50%);
            color: #ffff;
            border-radius: 0.5rem;
            padding: 1rem;
            @media screen and (min-width: 900px) {
                height: 12.5rem;
                filter: none;
                padding: 1rem;
                font-size: 1.25em;
                line-height: 1.6rem;
                border-radius: 1rem;
            }
        }

        &--image {
            float: left;
            padding: 0;
            margin-right: 1rem;
            width: 8.5rem;
            border-radius: 0.25rem;
            @media screen and (min-width: 900px) {
                width: 15rem;
                border-radius: 0.5rem;
                margin: 0.5rem 1rem 0.5rem 0.5rem;
            }
        }

        &--text {
            @media screen and (min-width: 900px) {
                margin-top: 0.5rem;
            }
            &--soon {
                margin-top: 0.5rem;
                @media screen and (min-width: 900px) {
                    margin-top: 1.5rem;
                }
            }
        }
    }

    &-formations, &-questionaries {
        margin-top: 1rem;
         @media screen and (min-width: 900px) {
            margin-top: 2rem;
            
         }
    }
}
